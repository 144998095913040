import React, { useEffect, useState, useRef } from "react";
import { useImmer } from "use-immer";
import { useBranch } from "../../hooks/useBranch";
import { useDevice } from "../../hooks/useDevice";
import BFIT_LOGO from "../../assets/images/bfit_logo.svg";
import InputControl from "../../components/shared/UI/InputControl";
import { useHistory } from "react-router-dom";
import banjosApi from "../../api/banjosApi";
import { useMainStore } from "../../Store/MainStore";
import { GENERAL_PATH } from "../../api/api-config";
import { deviceDetect } from "react-device-detect";
import { useFocusNext } from "../../hooks/useFocusNext";
import { useLogger } from "../../hooks/useLogger";
import { version } from "../../../package.json";
import Page from "../../components/Page";

import {
  Autocomplete,
  Container,
  Stack,
  TextField,
  styled,
  Alert,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import arraySort from "array-sort";
import SimpleReactValidator from "simple-react-validator";
import { getTenantId } from "../../helper/Common";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export const Setup = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const history = useHistory();
  const focusItem = useFocusNext();
  const { AppState } = useMainStore();
  const deviceInfo = JSON.parse(localStorage.getItem("DeviceInfo"));
  const [setupState, setSetupState] = useImmer({
    branchId: null,
    deviceId: null,
    groupId: 0,
    isVerified: false,
    accessCode: "",
    verifying: false,
    error: "",
  });
  const { branch } = useBranch(true, null);
  const { device, isBusy, onRegister } = useDevice(
    false,
    0,
    setupState.branchId
  );

  const onChange = (event) => {
    const { name, value } = event.target;
    setSetupState((draft) => {
      draft[name] = value;
    });
  };

  const handleKeyEvent = (event) => {
    if (event.code === "ArrowRight") {
      // console.log(event);
      event.preventDefault();
    }
  };

  let branchList =
    branch &&
    branch.map((item) => {
      return {
        value: item.branchId,
        label: `${item.name.trim()}`,
      };
    });

  const deviceList =
    device &&
    device.map((item) => {
      return {
        value: item.deviceId,
        label: `${item.name.trim()} (${
          item.accessToken ? "Registered" : "Available"
        })`,
      };
    });

  useEffect(() => {
    if (!getTenantId()) {
      history.replace("/login");
    } else if (deviceInfo !== null) {
      setSetupState((draft) => {
        draft.branchId = deviceInfo.branchId;
        draft.deviceId = deviceInfo.deviceId;
      });
    }

    // document.addEventListener("keydown", handleKeyEvent);

    return () => {};
  }, []);
  const onConfirm = () => {
    if (setupState.deviceId > 0) {
      onRegister(setupState.deviceId, deviceDetect());
    }
  };

  const onStoreChange = (name, data) => {
    setSetupState((draft) => {
      draft[name] = data ? data.value : null;
    });
  };

  const verifyPin = () => {
    setSetupState((draft) => {
      draft.verifying = !draft.verifying;
    });
    banjosApi
      .post(`auth/verify/${setupState.branchId}/${setupState.accessCode}`)
      .then((res) => {
        setSetupState((draft) => {
          draft.error = res.data === true ? "" : "Invalid access code";
          draft.isVerified = res.data === true ? true : false;
          draft.verifying = !draft.verifying;
        });
      })
      .catch((err) => {
        setSetupState((draft) => {
          draft.error = "Invalid access code";
          draft.verifying = !draft.verifying;
        });
      });
  };

  return (
    <Page>
      <ContentStyle>
        <Container maxWidth="sm">
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              if (validator.current.allValid()) {
                verifyPin();
              } else {
                validator.current.showMessages();
                forceUpdate(1);
              }
            }}
          >
            <Stack spacing={3}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={
                    AppState.config?.logo
                      ? GENERAL_PATH + AppState.config.logo
                      : BFIT_LOGO
                  }
                  width="150"
                  alt="Logo"
                />
              </Box>
              {setupState.error && (
                <Alert severity="error">{setupState.error}</Alert>
              )}
              <Autocomplete
                id="branchId"
                disabled={setupState.isVerified}
                options={arraySort(branchList, "label")}
                getOptionLabel={(option) => option.label}
                value={
                  branchList
                    ? setupState.branchId &&
                      branchList.find((c) => c.value === setupState.branchId)
                    : null
                }
                onChange={(e, value) => onStoreChange("branchId", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    inputRef={(input) => focusItem(input)}
                    required
                    label="Store"
                  />
                )}
              />
              <TextField
                name="accessCode"
                label="Access Code"
                inputRef={(input) => focusItem(input)}
                autoComplete="false"
                disabled={setupState.isVerified}
                type="password"
                inputMode="numeric"
                value={setupState.accessCode}
                onChange={onChange}
                error={validator.current.message(
                  "accessCode",
                  setupState.accessCode,
                  "required"
                )}
                helperText={validator.current.message(
                  "accessCode",
                  setupState.accessCode,
                  "required"
                )}
              />
              {setupState.isVerified === true && (
                <>
                  <Autocomplete
                    options={arraySort(deviceList, "label")}
                    getOptionLabel={(option) => option.label}
                    value={
                      deviceList
                        ? setupState.deviceId &&
                          deviceList.find(
                            (c) => c.value === setupState.deviceId
                          )
                        : null
                    }
                    onChange={(e, value) => onStoreChange("deviceId", value)}
                    renderInput={(params) => (
                      <TextField {...params} required label="Device" />
                    )}
                  />
                  {deviceInfo &&
                    device.length > 0 &&
                    device.filter((c) => c.deviceId === +setupState.deviceId)[0]
                      ?.accessToken &&
                    +setupState.deviceId !== deviceInfo.deviceId &&
                    "The device is already in use"}
                </>
              )}
            </Stack>
            {!setupState.isVerified ? (
              <LoadingButton
                sx={{ mt: 2 }}
                ref={(input) => focusItem(input)}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={setupState.verifying}
              >
                Confirm
              </LoadingButton>
            ) : (
              <>
                <LoadingButton
                  sx={{ mt: 2 }}
                  ref={(input) => focusItem(input)}
                  fullWidth
                  size="large"
                  variant="contained"
                  loading={isBusy}
                  onClick={onConfirm}
                >
                  Confirm
                </LoadingButton>
                <LoadingButton
                  sx={{ mt: 2 }}
                  ref={(input) => focusItem(input)}
                  fullWidth
                  color="error"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    localStorage.removeItem("DeviceInfo");
                    localStorage.removeItem("tid");
                    window.location.reload();
                  }}
                >
                  Reset
                </LoadingButton>
              </>
            )}
          </form>
          <Typography sx={{ mt: 2 }} textAlign="center">
            v {version}
          </Typography>
        </Container>
      </ContentStyle>
    </Page>
  );

  // return (
  //   <div className="container setup-page">
  //     <div className="row justify-content-center">
  //       <div className="col-md-4 setup-card">
  //         <div className="card border-0">
  //           <div className="card-body">
  //             <div className="text-center">
  //               <img
  //                 src={
  //                   AppState.config?.logo && GENERAL_PATH + AppState.config.logo
  //                 }
  //                 width="150"
  //                 alt="Logo"
  //               />
  //               {/* <div>
  //                 {window.location.protocol}//{window.location.host}
  //               </div> */}
  //             </div>
  //             <AvForm onValidSubmit={verifyPin}>
  //               <div className="mb-3">
  //                 <label htmlFor="branchId" className="form-label">
  //                   Store
  //                 </label>
  //                 <select
  //                   ref={focusItem}
  //                   disabled={setupState.isVerified}
  //                   className="form-control"
  //                   name="branchId"
  //                   autoFocus={true}
  //                   value={setupState.branchId}
  //                   onChange={onChange}
  //                   required
  //                 >
  //                   <option value="">Choose</option>
  //                   {branch &&
  //                     branch.map((item) => {
  //                       return (
  //                         <option key={item.branchId} value={item.branchId}>
  //                           {item.name}
  //                         </option>
  //                       );
  //                     })}
  //                 </select>
  //               </div>
  //               <div className="mb-3">
  //                 <label>Please enter your access code</label>
  //                 <InputControl
  //                   ref={focusItem}
  //                   disabled={setupState.isVerified}
  //                   type="password"
  //                   pattern="[0-9]*"
  //                   inputMode="numeric"
  //                   className="form-control"
  //                   name="accessCode"
  //                   value={setupState.accessCode}
  //                   onChange={onChange}
  //                   required
  //                 />
  //                 {setupState.error !== "" && (
  //                   <span className="text-danger">{setupState.error}</span>
  //                 )}
  //               </div>
  //               {!setupState.isVerified && (
  //                 <div className="text-center">
  //                   <LoadingButton
  //                     ref={focusItem}
  //                     disabled={setupState.accessCode === null}
  //                     type="submit"
  //                     isBusy={setupState.verifying}
  //                     busyText="Authenticating..."
  //                     className="btn app-btn-primary theme-btn mx-auto"
  //                   >
  //                     Confirm
  //                   </LoadingButton>
  //                 </div>
  //               )}

  //               {setupState.isVerified === true && (
  //                 <>
  //                   <div className="mb-3">
  //                     <label htmlFor="deviceGroup" className="form-label">
  //                       Device
  //                     </label>
  //                     <select
  //                       ref={focusItem}
  //                       className="form-control"
  //                       name="deviceId"
  //                       autoFocus={true}
  //                       value={setupState.deviceId}
  //                       onChange={onChange}
  //                     >
  //                       <option value="">
  //                         {isBusy ? "Loading..." : "Choose"}
  //                       </option>
  //                       {device.length > 0 &&
  //                         device.map((item) => {
  //                           return (
  //                             <option key={item.deviceId} value={item.deviceId}>
  //                               {item.name} (
  //                               {item.accessToken ? "Registered" : "Available"})
  //                             </option>
  //                           );
  //                         })}
  //                     </select>
  //                     <span className="text-danger">
  //                       {deviceInfo &&
  //                         device.length > 0 &&
  //                         device.filter(
  //                           (c) => c.deviceId === +setupState.deviceId
  //                         )[0]?.accessToken &&
  //                         +setupState.deviceId !== deviceInfo.deviceId &&
  //                         "The device is already in use"}
  //                     </span>
  //                   </div>
  //                   <div className="text-center">
  //                     <LoadingButton
  //                       ref={focusItem}
  //                       isBusy={isBusy}
  //                       busyText="Registering..."
  //                       className="btn app-btn-primary theme-btn mx-auto"
  //                       onClick={onConfirm}
  //                     >
  //                       Confirm
  //                     </LoadingButton>
  //                     <LoadingButton
  //                       ref={focusItem}
  //                       isBusy={false}
  //                       busyText="Confirming..."
  //                       className="btn btn-danger theme-btn mx-auto ms-2"
  //                       onClick={() => {
  //                         localStorage.removeItem("DeviceInfo");
  //                         window.location.reload();
  //                       }}
  //                     >
  //                       Clear
  //                     </LoadingButton>
  //                   </div>
  //                 </>
  //               )}
  //               <div className="text-center mt-2">
  //                 Version: <strong>{version}</strong>
  //               </div>
  //             </AvForm>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
