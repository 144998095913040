import React, { useState, useRef, useEffect } from "react";
import BFIT_LOGO from "../../assets/images/bfit_logo.svg";
import BFIT_LOGO_ONLY from "../../assets/images/bfit_logo_only.svg";
import { useImmer } from "use-immer";
import banjosApi from "../../api/banjosApi";
import { useHistory } from "react-router";
import { version } from "../../../package.json";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import SimpleReactValidator from "simple-react-validator";
import Page from "../../components/Page";
import { getTenantId } from "../../helper/Common";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export const Auth = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const history = useHistory();
  const [auth, setAuth] = useImmer({
    isBusy: false,
    errors: "",
    tenant: "",
    data: {
      playerCode: "",
    },
  });

  useEffect(() => {
    if (getTenantId()) {
      window.location.href = "device/config";
    }
  }, []);

  const onLogin = (e) => {
    if (e) e.preventDefault();
    setAuth((draft) => {
      draft.isBusy = true;
    });
    banjosApi
      .post("default/validate-player", auth.data)
      .then((res) => {
        setAuth((draft) => {
          draft.errors = "";
          draft.isBusy = false;
          draft.tenant = res.data;
        });
        localStorage.setItem("tid", res.data);
        window.location.href = "device/config";
        // history.push("device/config");
      })
      .catch((err) => {
        setAuth((draft) => {
          draft.isBusy = false;
          draft.errors = err.response
            ? err.response.data.message
            : "Unable to connect";
        });
      });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setAuth((draft) => {
      draft.data[name] = value;
    });
  };
  return (
    <RootStyle title="Login">
      {/* <MHidden width="mdDown"> */}
      <SectionStyle>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}></Typography>
        <Box component={"img"} sx={{ p: 10 }} src={BFIT_LOGO} width="100" />
      </SectionStyle>
      {/* </MHidden> */}

      <Container maxWidth="sm">
        <ContentStyle>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              if (validator.current.allValid()) {
                onLogin();
              } else {
                validator.current.showMessages();
                forceUpdate(1);
              }
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Welcome to bfitds
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Please enter your player code to continue...
                </Typography>
                {/* <Box
                component="img"
                src={BFIT_LOGO_ONLY}
                sx={{ width: 32, height: 32 }}
              /> */}
              </Box>
            </Stack>
            <Stack spacing={3}>
              {auth.errors && <Alert severity="error">{auth.errors}</Alert>}
              <TextField
                autoFocus
                name="playerCode"
                label="Player Code"
                disabled={auth.isBusy}
                onChange={onChange}
                value={auth.data.playerCode}
                error={validator.current.message(
                  "playerCode",
                  auth.data.playerCode,
                  "required"
                )}
                helperText={validator.current.message(
                  "playerCode",
                  auth.data.playerCode,
                  "required"
                )}
              />
            </Stack>
            <LoadingButton
              sx={{ mt: 2 }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={auth.isBusy}
            >
              Continue
            </LoadingButton>
            <Typography sx={{ mt: 2 }} textAlign="center">
              v {version}
            </Typography>
          </form>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};
