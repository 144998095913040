import React from "react";
import { GENERAL_PATH } from "../../../api/api-config";
import { useMainStore } from "../../../Store/MainStore";
import BFIT_LOGO from "../../../assets/images/bfit_logo.svg";
import { Box, CircularProgress } from "@mui/material";
export const Loading = (props) => {
  const { AppState } = useMainStore();

  return (
    <>
      <Box
        sx={{ height: "100vh" }}
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
      >
        <Box sx={{ textAlign: "center" }}>
          <>
            <img
              src={
                AppState.config?.logo
                  ? GENERAL_PATH + AppState.config.logo
                  : BFIT_LOGO
              }
              alt="bfit logo"
              width="200"
            />
            {props.text && <h4>{props.text}</h4>}
          </>
          <CircularProgress />
        </Box>
      </Box>
    </>
  );
};
