import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import banjosApi from "../api/banjosApi";
import moment from "moment";
export const useProof = () => {
  const [proofs, setProofs] = useImmer({
    isBusy: false,
    lastSynched: null,
    data: [],
  });
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("proofs"));
    if (!localData) {
      localStorage.setItem("proofs", JSON.stringify(proofs));
    }
  }, []);

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem("proofs"));
    const syncDiff = moment().diff(moment(localData.lastSynched), "minutes");
    if (syncDiff >= 10 && !proofs.isBusy) {
      SyncToServer();
    }
  }, [proofs]);

  const SyncToServer = () => {
    let localData = JSON.parse(localStorage.getItem("proofs"));

    localData.data = localData.data.filter((c) =>
      moment(c.startTime).isSameOrAfter(localData.lastSynched)
    );
    setProofs((draft) => {
      draft.isBusy = true;
    });
    banjosApi
      .post("proof", localData.data)
      .then((res) => {
        //success
        localData.lastSynched = moment().toISOString();
        localStorage.setItem("proofs", JSON.stringify(localData));
        setProofs((draft) => {
          draft.isBusy = false;
        });
      })
      .catch((err) => {
        //failed
        setProofs((draft) => {
          draft.isBusy = false;
        });
      });
  };

  const AddProof = (
    deviceId,
    playlistId,
    playlistName,
    contentId,
    contentName,
    startTime,
    endTime
  ) => {
    const data = {
      deviceId,
      playlistId,
      playlistName,
      contentId,
      contentName,
      startTime,
      endTime,
    };
    // console.log("proof", data);
    let localData = JSON.parse(localStorage.getItem("proofs"));
    localData.data.push(data);
    localStorage.setItem("proofs", JSON.stringify(localData));

    setProofs((draft) => {
      draft.data.push(data);
    });

    // banjosApi
    //   .post("proof", { deviceId, playlistId, contentId, startTime, endTime })
    //   .then((res) => {
    //     //success
    //   })
    //   .catch((err) => {
    //     //failed
    //   });
  };

  return { AddProof };
};
