import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { enableAllPlugins } from "immer";
import { DeviceInfo } from "./helper/Common";
import * as Sentry from "@sentry/react";
import { Offline } from "@sentry/integrations";

enableAllPlugins();

Sentry.setContext("deviceInfo", DeviceInfo());
Sentry.init({
  dsn: "https://db4cc06823dd41758db28528cff1644d@o1150046.ingest.sentry.io/6265055",
  integrations: [new Offline()],
  enabled: process.env.NODE_ENV !== "development",
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
